// src/components/AIAdvisor.js
import React, { useState } from 'react';
import { Paper, Typography, Button, CircularProgress, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const AIAdvisor = ({ results, onGenerateExplanation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');

  const topics = [
    { value: 'savingsRate', label: 'Savings Rate' },
    { value: 'retirementAge', label: 'Retirement Age' },
    { value: 'safeWithdrawalRate', label: 'Safe Withdrawal Rate' },
    { value: 'investmentStrategy', label: 'Investment Strategy' },
  ];

  const handleExplanationRequest = async () => {
    console.log('Explanation requested for topic:', selectedTopic);
    console.log('Results passed to AIAdvisor:', results);
    setIsLoading(true);
    try {
      const explanation = await onGenerateExplanation(selectedTopic, results);
      console.log('Received explanation:', explanation);
      setResponse(explanation);
    } catch (error) {
      console.error('Error generating explanation:', error);
      setResponse('An error occurred while generating the explanation.');
    }
    setIsLoading(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h5" gutterBottom>AI FIRE Advisor</Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select a topic for explanation</InputLabel>
        <Select
          value={selectedTopic}
          onChange={(e) => setSelectedTopic(e.target.value)}
        >
          {topics.map((topic) => (
            <MenuItem key={topic.value} value={topic.value}>{topic.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button 
        variant="contained" 
        onClick={handleExplanationRequest} 
        disabled={isLoading || !selectedTopic}
        sx={{ mb: 2 }}
      >
        Get AI Insights
      </Button>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Typography variant="body1">{response}</Typography>
      )}
    </Paper>
  );
};

export default AIAdvisor;