// src/components/ScenarioManager.js
import React, { useState } from 'react';
import { Box, Button, TextField, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ScenarioManager = ({ scenarios, onAddScenario, onUpdateScenario, onDeleteScenario, onSelectScenario }) => {
  const [newScenarioName, setNewScenarioName] = useState('');
  const [editingScenario, setEditingScenario] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleAddScenario = () => {
    if (newScenarioName.trim()) {
      onAddScenario(newScenarioName.trim());
      setNewScenarioName('');
    }
  };

  const handleEditScenario = (scenario) => {
    setEditingScenario({ ...scenario });
    setIsDialogOpen(true);
  };

  const handleUpdateScenario = () => {
    if (editingScenario) {
      onUpdateScenario(editingScenario);
      setIsDialogOpen(false);
      setEditingScenario(null);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          value={newScenarioName}
          onChange={(e) => setNewScenarioName(e.target.value)}
          placeholder="New Scenario Name"
          variant="outlined"
          size="small"
          sx={{ mr: 1 }}
        />
        <Button variant="contained" onClick={handleAddScenario}>
          Add Scenario
        </Button>
      </Box>
      <List>
        {scenarios.map((scenario) => (
          <ListItem key={scenario.id} button onClick={() => onSelectScenario(scenario)}>
            <ListItemText primary={scenario.name} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEditScenario(scenario)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => onDeleteScenario(scenario.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Edit Scenario</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Scenario Name"
            type="text"
            fullWidth
            value={editingScenario?.name || ''}
            onChange={(e) => setEditingScenario({ ...editingScenario, name: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleUpdateScenario}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ScenarioManager;