import React from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Paper } from '@mui/material';
import { formatCurrency } from '../../utils/formatters';

const SavingsTimelineChart = ({ data }) => {
  const theme = useTheme();

  const formatYAxis = (value) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `$${(value / 1000).toFixed(0)}K`;
    }
    return `$${value}`;
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        Savings Growth Timeline
      </Typography>
      <Box sx={{ height: 400, width: '100%' }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 10,
              bottom: 0,
            }}
          >
            <XAxis 
              dataKey="year" 
              stroke={theme.palette.text.primary}
              tick={{ fill: theme.palette.text.primary }}
            />
            <YAxis 
              tickFormatter={formatYAxis} 
              stroke={theme.palette.text.primary}
              tick={{ fill: theme.palette.text.primary }}
              width={80}
            />
            <Tooltip 
              formatter={(value) => formatCurrency(value)}
              contentStyle={{ 
                backgroundColor: theme.palette.background.paper, 
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary
              }}
            />
            <Legend 
              wrapperStyle={{
                color: theme.palette.text.primary
              }}
            />
            <Area 
              type="monotone" 
              dataKey="totalSavings" 
              name="Total Savings (Future Value)" 
              stroke={theme.palette.primary.main} 
              fill={theme.palette.primary.main} 
              fillOpacity={0.3}
            />
            <Area 
              type="monotone" 
              dataKey="savingsInTodaysDollars" 
              name="Savings (Adjusted for Inflation)" 
              stroke={theme.palette.secondary.main} 
              fill={theme.palette.secondary.main} 
              fillOpacity={0.3}
            />
            <Area 
              type="monotone" 
              dataKey="contributions" 
              name="Total Contributions" 
              stroke={theme.palette.success.main} 
              fill={theme.palette.success.main} 
              fillOpacity={0.3}
            />
            <Area 
              type="monotone" 
              dataKey="investmentGrowth" 
              name="Investment Growth" 
              stroke={theme.palette.warning.main} 
              fill={theme.palette.warning.main} 
              fillOpacity={0.3}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
      <Typography variant="body2" sx={{ mt: 2, color: theme.palette.text.secondary }}>
        "Total Savings (Future Value)" shows the projected total amount, while "Savings (Adjusted for Inflation)" shows the equivalent purchasing power in today's dollars. "Total Contributions" represents the sum of all contributions made, and "Investment Growth" shows the returns generated by investments.
      </Typography>
    </Paper>
  );
};

export default SavingsTimelineChart;