import React, { useState } from 'react';
import { Typography, Grid, Button, Box, Tooltip, IconButton, Accordion, AccordionSummary, AccordionDetails, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function InputForm({ onCalculate, initialData }) {
  const [formData, setFormData] = useState(initialData || {
    currentAge: '',
    retirementAge: '',
    lifeExpectancy: '',
    currentSavings: '',
    monthlyContribution: '',
    expectedReturn: '',
    expectedReturnVariance: '',
    inflationRate: '',
    assets: {
      home: '',
      cars: '',
      stocks: '',
      otherInvestments: '',
      expectedWindfall: ''
    },
    liabilities: {
      mortgage: '',
      carLoans: '',
      otherDebts: ''
    },
    advancedOptions: {
      lifeExpectancy: '',
      annualExpenses: '',
      healthcareCosts: '',
      additionalIncomeInRetirement: '',
      taxFreeIncomeInRetirement: '',
      socialSecurityBenefits: '',
      socialSecurityStartAge: '',
      taxRateInRetirement: '',
      desiredLegacyAmount: '',
      majorFutureExpenses: '',
      retirementAccountTypes: [],
      assetAllocationStrategy: '',
      rebalancingFrequency: '',
      withdrawalStrategy: '',
      plannedMajorLifeEvents: ''
    }
  });

  const handleInputChange = (name, value, category = null) => {
    if (category) {
      setFormData(prevData => ({
        ...prevData,
        [category]: {
          ...prevData[category],
          [name]: value
        }
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onCalculate(formData);
  };

  const renderTextField = (name, label, tooltip, emoji, isPercentage = false, isCurrency = false, category = null) => (
    <Grid item xs={12} sm={6} key={name}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <NumericFormat
          customInput={TextField}
          fullWidth
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '8px', fontSize: '1.2em' }}>{emoji}</span>
              {label}
            </div>
          }
          value={category ? formData[category][name] : formData[name]}
          onValueChange={(values) => handleInputChange(name, values.value, category)}
          thousandSeparator={true}
          isAllowed={(values) => {
            const { floatValue } = values;
            return floatValue >= 0;
          }}
          {...(isPercentage ? {
            suffix: '%',
            decimalScale: 2,
            isAllowed: ({ floatValue }) => floatValue >= 0 && floatValue <= 100
          } : {})}
          {...(isCurrency ? {
            prefix: '$',
            decimalScale: 0
          } : {})}
        />
        <Tooltip title={tooltip} placement="top-start" arrow>
          <IconButton size="small" style={{ marginLeft: '5px' }} tabIndex={-1}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Grid>
  );

  const renderSelectField = (name, label, tooltip, emoji, options, category = 'advancedOptions') => (
    <Grid item xs={12} sm={6} key={name}>
      <FormControl fullWidth variant="outlined">
        <InputLabel id={`${name}-label`}>
          <span style={{ marginRight: '8px', fontSize: '1.2em' }}>{emoji}</span>
          {label}
        </InputLabel>
        <Select
          labelId={`${name}-label`}
          id={name}
          value={formData[category][name]}
          onChange={(e) => handleInputChange(name, e.target.value, category)}
          label={
            <span>
              <span style={{ marginRight: '8px', fontSize: '1.2em' }}>{emoji}</span>
              {label}
            </span>
          }
          sx={{
            '& .MuiSelect-select': { 
              display: 'flex', 
              alignItems: 'center',
              minHeight: '1.4375em',
              padding: '16.5px 14px',
            }
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
          ))}
        </Select>
        <Tooltip title={tooltip} placement="top-start" arrow>
          <IconButton 
            size="small" 
            style={{ position: 'absolute', right: -28, top: 8 }} 
            tabIndex={-1}
          >
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </FormControl>
    </Grid>
  );

  return (
    <Box className="input-form">
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
        💰 Your Financial Information
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {renderTextField('currentAge', 'Current Age', 'Your current age in years.', '📆')}
          {renderTextField('retirementAge', 'Retirement Age', 'The age at which you plan to retire.', '🏖️')}
          {renderTextField('lifeExpectancy', 'Life Expectancy', 'Your expected lifespan.', '⏳', false, false, 'advancedOptions')}
          {renderTextField('currentSavings', 'Current Savings', 'The total amount you have saved for retirement so far.', '🏦', false, true)}
          {renderTextField('monthlyContribution', 'Monthly Contribution', 'How much you plan to save each month for retirement.', '💸', false, true)}
          {renderTextField('expectedReturn', 'Expected Annual Return', 'Expected annual return on your investments. Historically, the stock market has returned an average of 7-10% annually.', '📈', true)}
          {renderTextField('expectedReturnVariance', 'Return Variance', 'The expected variance in annual returns. This helps in running more accurate Monte Carlo simulations.', '📊', true)}
          {renderTextField('inflationRate', 'Inflation Rate', 'The expected average annual inflation rate. Historically, this has been around 2-3% in the US.', '🏷️', true)}
        </Grid>

        <Accordion sx={{ mt: 3 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Advanced Options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {renderTextField('annualExpenses', 'Annual Expenses in Retirement', 'Your expected annual expenses in retirement.', '🛒', false, true, 'advancedOptions')}
              {renderTextField('healthcareCosts', 'Annual Healthcare Costs in Retirement', 'Your expected annual healthcare costs in retirement.', '🏥', false, true, 'advancedOptions')}
              {renderTextField('additionalIncomeInRetirement', 'Additional Annual Income in Retirement', 'Any additional annual income you expect to receive in retirement (e.g., part-time work, rental income).', '💵', false, true, 'advancedOptions')}
              {renderTextField('taxFreeIncomeInRetirement', 'Annual Tax-Free Income in Retirement', 'Any annual tax-free income you expect to receive in retirement (e.g., Roth IRA distributions).', '🏦', false, true, 'advancedOptions')}
              {renderTextField('socialSecurityBenefits', 'Annual Social Security Benefits', 'Your expected annual Social Security benefits.', '👵', false, true, 'advancedOptions')}
              {renderTextField('socialSecurityStartAge', 'Social Security Start Age', 'The age at which you plan to start taking Social Security benefits.', '🎂', false, false, 'advancedOptions')}
              {renderTextField('taxRateInRetirement', 'Tax Rate in Retirement', 'Your expected tax rate during retirement.', '📑', true, false, 'advancedOptions')}
              {renderTextField('desiredLegacyAmount', 'Desired Legacy Amount', 'The amount you wish to leave to heirs.', '🏆', false, true, 'advancedOptions')}
              {renderTextField('majorFutureExpenses', 'Major Future Expenses', 'Any significant expenses you anticipate (e.g., children\'s education).', '🎓', false, true, 'advancedOptions')}
              {renderSelectField('assetAllocationStrategy', 'Asset Allocation Strategy', 'Choose your preferred asset allocation strategy.', '📊', [
                { value: 'conservative', label: 'Conservative' },
                { value: 'moderate', label: 'Moderate' },
                { value: 'aggressive', label: 'Aggressive' }
              ])}
              {renderSelectField('rebalancingFrequency', 'Rebalancing Frequency', 'Choose how often you plan to rebalance your portfolio.', '🔄', [
                { value: 'annually', label: 'Annually' },
                { value: 'semiannually', label: 'Semi-annually' },
                { value: 'quarterly', label: 'Quarterly' },
              ])}
              {renderSelectField('withdrawalStrategy', 'Withdrawal Strategy', 'Choose your preferred withdrawal strategy for retirement.', '💼', [
                { value: 'fixedPercentage', label: 'Fixed Percentage' },
                { value: 'variablePercentage', label: 'Variable Percentage' },
                { value: 'bucketStrategy', label: 'Bucket Strategy' }
              ])}
              {renderTextField('plannedMajorLifeEvents', 'Planned Major Life Events (Cost/Benefit)', 'The financial impact of significant life events you anticipate (e.g., selling a house, downsizing). Use positive values for income and negative for expenses.', '🏠', false, true, 'advancedOptions')}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 3 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Additional Assets</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {renderTextField('home', 'Home Value', 'The current value of your home.', '🏠', false, true, 'assets')}
              {renderTextField('cars', 'Vehicles Value', 'The total value of your vehicles.', '🚗', false, true, 'assets')}
              {renderTextField('stocks', 'Stocks', 'The value of your stock portfolio.', '📊', false, true, 'assets')}
              {renderTextField('otherInvestments', 'Other Investments', 'The value of any other investments you have.', '💼', false, true, 'assets')}
              {renderTextField('expectedWindfall', 'Expected Windfall', 'Any large sum of money you expect to receive in the future (e.g., inheritance).', '💰', false, true, 'assets')}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 3 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Liabilities</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {renderTextField('mortgage', 'Mortgage', 'The remaining balance on your mortgage.', '🏘️', false, true, 'liabilities')}
              {renderTextField('carLoans', 'Car Loans', 'The total balance of your car loans.', '🚙', false, true, 'liabilities')}
              {renderTextField('otherDebts', 'Other Debts', 'The total of any other debts you have.', '💳', false, true, 'liabilities')}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button type="submit" variant="contained" color="primary" size="large" className="calculate-button">
            🚀 Calculate FIRE
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default InputForm;