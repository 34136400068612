import React, { useState } from 'react';
import { 
  Paper, Typography, Grid, Box, Button, 
  Select, MenuItem, Modal
} from '@mui/material';
import { formatCurrency, formatPercentage } from '../utils/formatters';

const SimulationDetail = ({ simulation, onClose }) => {
  return (
    <Modal open={true} onClose={onClose}>
      <Paper sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', maxHeight: '90%', overflow: 'auto', p: 4 }}>
        <Typography variant="h6">{`${simulation.startYear}-${simulation.endYear}`}</Typography>
        {/* Add more details here */}
        <Button onClick={onClose}>Close</Button>
      </Paper>
    </Modal>
  );
};

const MonteCarloVisualization = ({ simulationResults }) => {
  const [sortBy, setSortBy] = useState('Date');
  const [showAllSimulations, setShowAllSimulations] = useState(false);
  const [selectedSimulation, setSelectedSimulation] = useState(null);

  if (!simulationResults || !simulationResults.results) {
    return <Typography>No simulation results available.</Typography>;
  }

  const { results } = simulationResults;

  const sortedResults = [...results].sort((a, b) => {
    switch (sortBy) {
      case 'Date': return b.startYear - a.startYear;
      case 'Success': return b.success - a.success;
      case 'End Portfolio Value': return b.finalPortfolioValue - a.finalPortfolioValue;
      case 'Average Portfolio Value': return b.averagePortfolioValue - a.averagePortfolioValue;
      case 'Average Available Spending': return b.averageWithdrawal - a.averageWithdrawal;
      case 'Average Stocks Return': return b.averageStocksReturn - a.averageStocksReturn;
      case 'Average Bonds Return': return b.averageBondsReturn - a.averageBondsReturn;
      case 'Average Inflation': return b.averageInflation - a.averageInflation;
      default: return 0;
    }
  });

  const displayedResults = showAllSimulations ? sortedResults : sortedResults.slice(0, 12);

  return (
    <Paper elevation={3} sx={{ p: 3, bgcolor: 'background.paper', color: 'text.primary' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Simulations</Typography>
        <Select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          size="small"
        >
          <MenuItem value="Date">Date</MenuItem>
          <MenuItem value="Success">Success</MenuItem>
          <MenuItem value="End Portfolio Value">End Portfolio Value</MenuItem>
          <MenuItem value="Average Portfolio Value">Average Portfolio Value</MenuItem>
          <MenuItem value="Average Available Spending">Average Available Spending</MenuItem>
          <MenuItem value="Average Stocks Return">Average Stocks Return</MenuItem>
          <MenuItem value="Average Bonds Return">Average Bonds Return</MenuItem>
          <MenuItem value="Average Inflation">Average Inflation</MenuItem>
        </Select>
      </Box>
      <Typography variant="body2" sx={{ mb: 2 }}>Click a simulation to view details.</Typography>
      <Grid container spacing={2}>
        {displayedResults.map((sim, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper 
              elevation={2} 
              sx={{ 
                p: 2, 
                bgcolor: 'background.default', 
                '&:hover': { bgcolor: 'action.hover' },
                cursor: 'pointer'
              }}
              onClick={() => setSelectedSimulation(sim)}
            >
              <Typography variant="subtitle1">{`${sim.startYear}–${sim.endYear}`}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body2">End portfolio</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>{formatCurrency(sim.finalPortfolioValue)}</Typography>
                  {sim.success ? ' ✅' : ' ❌'}
                  {' 💰'}
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {results.length > 12 && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            There are {results.length - 12} more simulations
          </Typography>
          <Button 
            variant="outlined" 
            onClick={() => setShowAllSimulations(!showAllSimulations)}
          >
            {showAllSimulations ? 'Show less' : 'Show all'}
          </Button>
        </Box>
      )}
      {selectedSimulation && (
        <SimulationDetail 
          simulation={selectedSimulation} 
          onClose={() => setSelectedSimulation(null)} 
        />
      )}
    </Paper>
  );
};

export default MonteCarloVisualization;