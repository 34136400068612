// src/components/RetirementSpendingSimulation.js
import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Paper, Grid, Select, MenuItem, FormControl, InputLabel, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { LineChart, Line, XAxis, YAxis, Tooltip as ChartTooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { formatCurrency } from '../utils/formatters';

const RetirementSpendingSimulation = ({ initialSavings, annualExpenses, expectedReturn, inflationRate }) => {
  const theme = useTheme();
  const [strategy, setStrategy] = useState('constantDollar');
  const [simulationData, setSimulationData] = useState([]);

  const strategies = {
    constantDollar: {
      name: "Constant Dollar",
      description: "Withdraws a fixed amount each year, adjusted for inflation. Starts with 4% of initial portfolio and increases with inflation annually."
    },
    percentageOfPortfolio: {
      name: "Percentage of Portfolio",
      description: "Withdraws a fixed percentage (4%) of the current portfolio value each year. This can lead to varying withdrawal amounts."
    },
    guytonKlinger: {
      name: "Guyton-Klinger",
      description: "A dynamic strategy that adjusts withdrawals based on portfolio performance. It increases or decreases withdrawals by 10% if the withdrawal rate falls outside the 4-6% range."
    }
  };

  const simulateRetirementSpending = useCallback(() => {
    let currentSavings = initialSavings;
    let currentExpenses = annualExpenses;
    const data = [];
    const initialWithdrawal = currentSavings * 0.04; // 4% initial withdrawal rate

    for (let year = 0; year < 50; year++) {
      let withdrawal;

      switch (strategy) {
        case 'constantDollar':
          withdrawal = initialWithdrawal * Math.pow(1 + inflationRate / 100, year);
          break;
        case 'percentageOfPortfolio':
          withdrawal = currentSavings * 0.04;
          break;
        case 'guytonKlinger':
          const previousWithdrawal = year > 0 ? data[year - 1].withdrawal : initialWithdrawal;
          if (withdrawal / currentSavings > 0.06) {
            withdrawal = previousWithdrawal * 0.9;
          } else if (withdrawal / currentSavings < 0.04) {
            withdrawal = previousWithdrawal * 1.1;
          } else {
            withdrawal = previousWithdrawal * (1 + inflationRate / 100);
          }
          break;
        default:
          withdrawal = initialWithdrawal;
      }

      currentSavings = (currentSavings - withdrawal) * (1 + expectedReturn / 100);
      currentExpenses *= (1 + inflationRate / 100);

      data.push({
        year,
        savings: Math.max(0, currentSavings),
        expenses: currentExpenses,
        withdrawal
      });

      if (currentSavings <= 0) break;
    }

    setSimulationData(data);
  }, [initialSavings, annualExpenses, expectedReturn, inflationRate, strategy]);

  useEffect(() => {
    simulateRetirementSpending();
  }, [simulateRetirementSpending]);

  const formatYAxis = (value) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `$${(value / 1000).toFixed(0)}K`;
    }
    return `$${value}`;
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Retirement Spending Simulation
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="withdrawal-strategy-label">Withdrawal Strategy</InputLabel>
            <Select
              labelId="withdrawal-strategy-label"
              value={strategy}
              label="Withdrawal Strategy"
              onChange={(e) => setStrategy(e.target.value)}
            >
              {Object.entries(strategies).map(([key, { name }]) => (
                <MenuItem key={key} value={key}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip title={strategies[strategy].description} placement="bottom-start" arrow>
            <IconButton size="small" sx={{ ml: 1 }}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom>
            Savings last for: {simulationData.length} years
          </Typography>
        </Grid>
        <Grid item xs={12} height={400}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={simulationData}
              margin={{
                top: 5,
                right: 30,
                left: 10,
                bottom: 5,
              }}
            >
              <XAxis 
                dataKey="year"
                stroke={theme.palette.text.primary}
                tick={{ fill: theme.palette.text.primary }}
              />
              <YAxis 
                tickFormatter={formatYAxis}
                stroke={theme.palette.text.primary}
                tick={{ fill: theme.palette.text.primary }}
                width={80}
              />
              <ChartTooltip 
                formatter={(value) => formatCurrency(value)}
                contentStyle={{ 
                  backgroundColor: theme.palette.background.paper, 
                  borderColor: theme.palette.divider,
                  color: theme.palette.text.primary
                }}
              />
              <Legend 
                wrapperStyle={{
                  color: theme.palette.text.primary
                }}
              />
              <Line type="monotone" dataKey="savings" name="Portfolio Value" stroke={theme.palette.primary.main} dot={false} />
              <Line type="monotone" dataKey="withdrawal" name="Annual Withdrawal" stroke={theme.palette.secondary.main} dot={false} />
              <Line type="monotone" dataKey="expenses" name="Annual Expenses" stroke={theme.palette.error.main} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RetirementSpendingSimulation;