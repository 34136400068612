// src/components/EducationalContent.js
import React from 'react';
import { Paper, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const EducationalContent = () => {
  const sections = [
    {
      title: "What is FIRE?",
      content: "FIRE stands for Financial Independence, Retire Early. It's a movement focused on extreme savings and investment to enable early retirement. The core principle is to save a large percentage of your income (often 50-70%) and invest it wisely to accumulate enough wealth to support your lifestyle without needing to work."
    },
    {
      title: "The 4% Rule",
      content: "The 4% rule is a guideline used to determine how much a retiree should withdraw from a retirement account each year. This rule states that you can withdraw 4% of your portfolio each year in retirement for a 30-year retirement without running out of money. It's based on historical data and assumes a portfolio of 50% stocks and 50% bonds."
    },
    {
      title: "Importance of Asset Allocation",
      content: "Asset allocation is the practice of dividing investment funds among different asset classes, such as stocks, bonds, and cash. The right asset allocation balances your tolerance for risk with your need for returns. Generally, younger investors can afford to take on more risk (and allocate more to stocks) because they have more time to recover from market downturns."
    },
    {
      title: "Impact of Inflation",
      content: "Inflation is the rate at which the general level of prices for goods and services is rising, eroding purchasing power. It's crucial to account for inflation in your retirement planning because it means you'll need more money in the future to maintain your standard of living. Historically, inflation in the US has averaged around 3% per year."
    }
  ];

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        Understanding FIRE
      </Typography>
      {sections.map((section, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{section.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{section.content}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Paper>
  );
};

export default EducationalContent;