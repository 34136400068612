import { getHistoricalReturns } from './historicalData';

// This function is used for individual FIRE calculations in the Monte Carlo simulation
export function calculateFIRE({
  currentAge,
  retirementAge,
  currentSavings,
  monthlyContribution,
  expectedReturn,
  inflationRate,
  assets,
  liabilities,
  advancedOptions = {}
}) {
  // Convert to numbers and provide default values
  currentAge = Number(currentAge) || 30;
  retirementAge = Number(retirementAge) || 65;
  currentSavings = Number(currentSavings) || 0;
  monthlyContribution = Number(monthlyContribution) || 0;
  expectedReturn = Number(expectedReturn) / 100 || 0.07; // Default to 7% if not provided
  inflationRate = Number(inflationRate) / 100 || 0.03; // Default to 3% if not provided

  const {
    lifeExpectancy = retirementAge + 25,  // Default to 25 years after retirement
    annualExpenses = 0,
    healthcareCosts = 0,
    additionalIncomeInRetirement = 0,
    taxFreeIncomeInRetirement = 0,
    socialSecurityBenefits = 0,
    socialSecurityStartAge = 67,
    taxRateInRetirement = 0,
    desiredLegacyAmount = 0,
    majorFutureExpenses = 0,
    withdrawalStrategy = 'fixedPercentage',
    plannedMajorLifeEvents = 0,
    useHistoricalReturns = false,
    stockAllocation = 0.6 // Added stock allocation parameter
  } = advancedOptions;

  // Ensure assets and liabilities are objects, defaulting to empty objects if undefined
  assets = assets || {};
  liabilities = liabilities || {};

  const totalAssets = Object.values(assets).reduce((sum, value) => sum + Number(value || 0), 0);
  const totalLiabilities = Object.values(liabilities).reduce((sum, value) => sum + Number(value || 0), 0);
  const initialNetWorth = currentSavings + totalAssets - totalLiabilities;

  const yearsToRetirement = Math.max(0, retirementAge - currentAge);
  const yearsInRetirement = Math.max(0, Number(lifeExpectancy) - retirementAge);
  const totalYears = yearsToRetirement + yearsInRetirement;

  // Ensure we're not creating arrays with negative lengths
  if (totalYears <= 0) {
    throw new Error("Invalid retirement timeline. Please check your age inputs.");
  }

  // Update historical returns usage
  let expectedReturns, inflationRates;
  if (useHistoricalReturns) {
    const historicalPeriod = getHistoricalReturns(currentAge, currentAge + totalYears - 1);
    expectedReturns = historicalPeriod.map(year => year.stockReturn * stockAllocation + year.bondReturn * (1 - stockAllocation));
    inflationRates = historicalPeriod.map(year => year.inflation);
  } else {
    expectedReturns = Array(totalYears).fill(expectedReturn);
    inflationRates = Array(totalYears).fill(inflationRate);
  }

  let totalSavings = initialNetWorth;
  let totalContributions = currentSavings;
  let totalInvestmentGrowth = 0;
  const savingsData = [];
  const withdrawalData = [];
  const netWorthData = [];
  
  // Accumulation phase
  for (let year = 0; year < yearsToRetirement; year++) {
    const currentYear = currentAge + year;
    let yearlyContribution = monthlyContribution * 12;
    
    const annualReturn = expectedReturns[year % expectedReturns.length];
    const annualInflation = inflationRates[year % inflationRates.length];
    
    totalSavings = totalSavings * (1 + annualReturn) + yearlyContribution;
    yearlyContribution *= (1 + annualInflation);
    
    // Add sanity check
    if (totalSavings > 1e9) { // If savings exceed 1 billion
      console.warn('Savings have exceeded 1 billion. This may indicate a calculation error.');
    }

    // Update inflation adjustment
    const averageInflation = inflationRates.reduce((sum, rate) => sum + rate, 0) / inflationRates.length;
    const inflationAdjustedSavings = totalSavings / Math.pow(1 + averageInflation, year + 1);
    
    const investmentGrowth = totalSavings - totalContributions;
    totalInvestmentGrowth = investmentGrowth;
    
    // Adjust for major future expenses and planned life events
    if (currentYear === Math.floor((currentAge + retirementAge) / 2)) {
      totalSavings -= Number(majorFutureExpenses) || 0;
    }
    totalSavings += Number(plannedMajorLifeEvents) || 0;
    
    savingsData.push({
      year: currentYear,
      totalSavings: Math.round(totalSavings),
      savingsInTodaysDollars: Math.round(inflationAdjustedSavings),
      contributions: Math.round(totalContributions),
      investmentGrowth: Math.round(totalInvestmentGrowth)
    });
    
    netWorthData.push({
      year: currentYear,
      netWorth: Math.round(totalSavings)
    });
  }

  const inflationAdjustedExpenses = (Number(annualExpenses) + Number(healthcareCosts)) * Math.pow(1 + inflationRates[yearsToRetirement - 1], yearsToRetirement);
  const safeWithdrawalRate = getSafeWithdrawalRate(withdrawalStrategy);
  const initialWithdrawal = totalSavings * safeWithdrawalRate;
  
  // Calculate FIRE Number
  const fireNumber = inflationAdjustedExpenses / safeWithdrawalRate;
  
  // Withdrawal phase
  let remainingSavings = totalSavings;
  for (let year = 0; year < yearsInRetirement; year++) {
    const currentYear = retirementAge + year;
    const withdrawal = calculateWithdrawal(withdrawalStrategy, initialWithdrawal, remainingSavings, year, inflationRates[yearsToRetirement + year - 1]);
    const expenses = inflationAdjustedExpenses * Math.pow(1 + inflationRates[yearsToRetirement + year - 1], year);
    const annualReturn = expectedReturns[yearsToRetirement + year - 1];
    const investmentGrowth = remainingSavings * annualReturn;
    
    const socialSecurityIncome = currentYear >= Number(socialSecurityStartAge) ? Number(socialSecurityBenefits) : 0;
    const additionalIncome = Number(additionalIncomeInRetirement) + Number(taxFreeIncomeInRetirement);
    const totalIncome = withdrawal + additionalIncome + socialSecurityIncome;
    const taxableIncome = totalIncome - Number(taxFreeIncomeInRetirement);
    const taxPaid = taxableIncome * (Number(taxRateInRetirement) / 100);
    
    withdrawalData.push({
      year: currentYear,
      portfolioValue: Math.round(remainingSavings),
      withdrawal: Math.round(withdrawal),
      expenses: Math.round(expenses),
      investmentGrowth: Math.round(investmentGrowth),
      socialSecurityIncome: Math.round(socialSecurityIncome),
      additionalIncome: Math.round(additionalIncome),
      taxPaid: Math.round(taxPaid)
    });
    
    netWorthData.push({
      year: currentYear,
      netWorth: Math.round(remainingSavings)
    });
    
    remainingSavings = Math.max(0, (remainingSavings - withdrawal + additionalIncome + socialSecurityIncome - taxPaid) * (1 + annualReturn));
    if (remainingSavings <= 0) break;
  }

  const finalPortfolioValue = withdrawalData[withdrawalData.length - 1]?.portfolioValue || 0;
  const legacyShortfall = Math.max(0, Number(desiredLegacyAmount) - finalPortfolioValue);
  const totalSavingsInTodaysDollars = totalSavings / Math.pow(1 + inflationRates[yearsToRetirement - 1], yearsToRetirement);

  return {
    totalSavings: Math.round(totalSavings),
    totalSavingsInTodaysDollars: Math.round(totalSavingsInTodaysDollars),
    totalAssets: Math.round(totalAssets),
    totalLiabilities: Math.round(totalLiabilities),
    netWorth: Math.round(initialNetWorth),
    totalContributions: Math.round(totalContributions),
    totalInvestmentGrowth: Math.round(totalInvestmentGrowth),
    inflationAdjustedExpenses: Math.round(inflationAdjustedExpenses),
    safeWithdrawalAmount: Math.round(initialWithdrawal),
    safeWithdrawalAmountInTodaysDollars: Math.round(initialWithdrawal / Math.pow(1 + inflationRates[yearsToRetirement - 1], yearsToRetirement)),
    yearsOfRetirement: withdrawalData.length,
    finalPortfolioValue: Math.round(finalPortfolioValue),
    legacyShortfall: Math.round(legacyShortfall),
    fireNumber: Math.round(fireNumber), // Add FIRE Number to the results
    assets,
    liabilities,
    savingsData,
    withdrawalData,
    netWorthData
  };
}

const getSafeWithdrawalRate = (strategy) => {
  switch (strategy) {
    case 'fixedPercentage':
      return 0.04; // 4% rule
    case 'variablePercentage':
      return 0.05; // Higher initial rate for variable strategy
    case 'bucketStrategy':
      return 0.035; // More conservative initial rate for bucket strategy
    default:
      return 0.04;
  }
};

const calculateWithdrawal = (strategy, initialWithdrawal, remainingSavings, year, inflationRate) => {
  switch (strategy) {
    case 'fixedPercentage':
      return initialWithdrawal * Math.pow(1 + inflationRate, year);
    case 'variablePercentage':
      return remainingSavings * 0.05; // 5% of current portfolio each year
    case 'bucketStrategy':
      // Simplified bucket strategy: use fixed percentage but adjust every 5 years
      return initialWithdrawal * Math.pow(1 + inflationRate, Math.floor(year / 5) * 5);
    default:
      return initialWithdrawal * Math.pow(1 + inflationRate, year);
  }
};