import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container, Typography, Tabs, Tab, Paper, Snackbar, Alert, CircularProgress, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import theme from './theme';
import InputForm from './components/InputForm';
import ResultsDisplay from './components/ResultsDisplay';
import SensitivityAnalysis from './components/SensitivityAnalysis';
import MonteCarloVisualization from './components/MonteCarloVisualization';
import EducationalContent from './components/EducationalContent';
import SavingsTimelineChart from './components/charts/SavingsTimelineChart';
import WithdrawalPhaseChart from './components/charts/WithdrawalPhaseChart';
import NetWorthProjectionChart from './components/charts/NetWorthProjectionChart';
import RetirementSpendingSimulation from './components/RetirementSpendingSimulation';
import ScenarioManager from './components/ScenarioManager';
import { calculateFIRE } from './utils/calculations';
import { runMonteCarloSimulation } from './utils/monteCarloSimulation';
import { saveDataToStorage, loadDataFromStorage, clearStorageData } from './utils/storageUtils';
import AIAdvisor from './components/AIAdvisor';
import AssetLiabilityPieChart from './components/charts/AssetLiabilityPieChart';

function App() {
  const [scenarios, setScenarios] = useState([]);
  const [activeScenario, setActiveScenario] = useState(null);
  const [results, setResults] = useState(null);
  const [monteCarloResults, setMonteCarloResults] = useState(null);
  const [simulationResults, setSimulationResults] = useState(null);
  const [mainTabValue, setMainTabValue] = useState(0);
  const [advancedTabValue, setAdvancedTabValue] = useState(0);
  const [error, setError] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const [newScenarioName, setNewScenarioName] = useState('');

  useEffect(() => {
    const savedData = loadDataFromStorage();
    if (savedData && savedData.scenarios) {
      setScenarios(savedData.scenarios);
      if (savedData.activeScenarioId) {
        const active = savedData.scenarios.find(s => s.id === savedData.activeScenarioId);
        setActiveScenario(active);
      }
    }
  }, []);

  const handleCalculate = async (formData) => {
    setIsCalculating(true);
    try {
      const calculationResults = calculateFIRE({
        ...formData,
        expectedReturn: formData.expectedReturn, // Assume this is already a percentage
        inflationRate: formData.inflationRate, // Assume this is already a percentage
      });
      const simulationResults = await runMonteCarloSimulation(formData);
      
      // Include assets and liabilities in the results
      const resultsWithAssetsAndLiabilities = {
        ...calculationResults,
        assets: formData.assets,
        liabilities: formData.liabilities
      };
      
      setResults(resultsWithAssetsAndLiabilities);
      setMonteCarloResults(simulationResults);
      setError(null);

      if (activeScenario) {
        const updatedScenario = { 
          ...activeScenario, 
          inputData: formData, 
          results: resultsWithAssetsAndLiabilities, 
          monteCarloResults: simulationResults 
        };
        updateScenario(updatedScenario);
      } else {
        setIsSaveDialogOpen(true);
      }
    } catch (err) {
      console.error("Calculation error:", err);
      setError("An error occurred during calculation. Please check your inputs and try again.");
    } finally {
      setIsCalculating(false);
    }
  };

  const addScenario = (name) => {
    const newScenario = { 
      id: Date.now(), 
      name, 
      inputData: activeScenario ? activeScenario.inputData : null, 
      results, 
      monteCarloResults 
    };
    const updatedScenarios = [...scenarios, newScenario];
    setScenarios(updatedScenarios);
    setActiveScenario(newScenario);
    saveScenarios(updatedScenarios, newScenario.id);
  };

  const updateScenario = (updatedScenario) => {
    const updatedScenarios = scenarios.map(s => s.id === updatedScenario.id ? updatedScenario : s);
    setScenarios(updatedScenarios);
    setActiveScenario(updatedScenario);
    saveScenarios(updatedScenarios, updatedScenario.id);
  };

  const deleteScenario = (id) => {
    const updatedScenarios = scenarios.filter(s => s.id !== id);
    setScenarios(updatedScenarios);
    if (activeScenario && activeScenario.id === id) {
      setActiveScenario(updatedScenarios[0] || null);
      setResults(updatedScenarios[0]?.results || null);
      setMonteCarloResults(updatedScenarios[0]?.monteCarloResults || null);
    }
    saveScenarios(updatedScenarios, updatedScenarios[0]?.id);
  };

  const selectScenario = (scenario) => {
    setActiveScenario(scenario);
    setResults(scenario.results);
    setMonteCarloResults(scenario.monteCarloResults);
    saveScenarios(scenarios, scenario.id);
  };

  const saveScenarios = (scenariosToSave, activeId) => {
    saveDataToStorage({ scenarios: scenariosToSave, activeScenarioId: activeId });
  };

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const handleAdvancedTabChange = (event, newValue) => {
    setAdvancedTabValue(newValue);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
  };

  const handleSaveDialogClose = () => {
    setIsSaveDialogOpen(false);
    setNewScenarioName('');
  };

  const handleSaveScenario = () => {
    if (newScenarioName.trim()) {
      addScenario(newScenarioName.trim());
      handleSaveDialogClose();
    }
  }

  const handleClearAllData = () => {
    clearStorageData();
    setScenarios([]);
    setActiveScenario(null);
    setResults(null);
    setMonteCarloResults(null);
    setMainTabValue(0);
    setAdvancedTabValue(0);
  };

  const handleGenerateExplanation = async (topic, results) => {
    console.log('Generating explanation for topic:', topic);
    console.log('Results:', results);
    try {
      const response = await fetch('/.netlify/functions/ai-advisor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          type: 'explain', 
          topic, 
          results: {
            savingsRate: results.monthlyContribution && results.annualIncome 
              ? (results.monthlyContribution * 12 / results.annualIncome) * 100 
              : null,
            retirementAge: results.retirementAge,
            safeWithdrawalRate: 4, // Assuming 4% rule, adjust if different
            totalSavings: results.totalSavings,
            yearsOfRetirement: results.yearsOfRetirement
          }
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        console.error('Error response:', data);
        throw new Error(`HTTP error! status: ${response.status}, message: ${data.error || 'Unknown error'}`);
      }
      console.log('Received data from API:', data);
      return data.result;
    } catch (error) {
      console.error('Error generating explanation:', error);
      return 'An error occurred while generating the explanation. ' + error.message;
    }
  };

  const handleRunSimulation = async () => {
    const initialData = activeScenario?.inputData || {
      currentAge: 30,
      retirementAge: 65,
      lifeExpectancy: 90,
      currentSavings: 100000,
      monthlyContribution: 1000,
      expectedReturn: 7,
      expectedReturnVariance: 15,
      inflationRate: 2,
      assets: {},
      liabilities: {},
      advancedOptions: {
        annualExpenses: 40000,
        healthcareCosts: 5000,
        additionalIncomeInRetirement: 0,
        taxFreeIncomeInRetirement: 0,
        socialSecurityBenefits: 0,
        socialSecurityStartAge: 67,
        taxRateInRetirement: 15,
        desiredLegacyAmount: 0,
        majorFutureExpenses: 0,
        withdrawalStrategy: 'fixedPercentage',
        plannedMajorLifeEvents: 0
      }
    };

    const results = await runMonteCarloSimulation(initialData, 1000);
    setSimulationResults(results);
    setMonteCarloResults(results);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ minHeight: '100vh', bgcolor: 'background.default', color: 'text.primary' }}>
        <Container maxWidth="lg">
          <Box sx={{ pt: 4, pb: 4 }}>
            <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700, color: theme.palette.primary.main }}>
              🔥 FIRE Calculator
            </Typography>
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
              <Tabs value={mainTabValue} onChange={handleMainTabChange} aria-label="main tabs">
                <Tab label="Calculator" />
                <Tab label="Scenarios" />
                <Tab label="Advanced Analysis" className="advanced-analysis-tab" />
                <Tab label="Learn" />
              </Tabs>
              <Box sx={{ mt: 2 }}>
                {mainTabValue === 0 && (
                  <>
                    <div className="input-form">
                      <InputForm onCalculate={handleCalculate} initialData={activeScenario?.inputData} />
                    </div>
                    {isCalculating && (
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <CircularProgress />
                      </Box>
                    )}
                    {results && !isCalculating && (
                      <>
                        <ResultsDisplay 
                          results={results}
                          assets={results.assets}
                          liabilities={results.liabilities}
                        />
                        <SavingsTimelineChart data={results.savingsData} />
                        <WithdrawalPhaseChart data={results.withdrawalData} />
                        <NetWorthProjectionChart data={results.netWorthData} />
                        <AssetLiabilityPieChart assets={results.assets} liabilities={results.liabilities} />
                        <AIAdvisor 
                          results={results}
                          onGenerateExplanation={handleGenerateExplanation}
                        />
                      </>
                    )}
                  </>
                )}
                {mainTabValue === 1 && (
                  <ScenarioManager
                    scenarios={scenarios}
                    activeScenario={activeScenario}
                    onAddScenario={addScenario}
                    onUpdateScenario={updateScenario}
                    onDeleteScenario={deleteScenario}
                    onSelectScenario={selectScenario}
                  />
                )}
                {mainTabValue === 2 && results && (
                  <>
                    <Tabs value={advancedTabValue} onChange={handleAdvancedTabChange} aria-label="advanced tabs">
                      <Tab label="Retirement Spending" />
                      <Tab label="Sensitivity Analysis" />
                      <Tab label="Monte Carlo" />
                    </Tabs>
                    <Box sx={{ mt: 2 }}>
                      {advancedTabValue === 0 && (
                        <RetirementSpendingSimulation 
                          initialSavings={results.totalSavings}
                          annualExpenses={results.inflationAdjustedExpenses}
                          expectedReturn={activeScenario?.inputData?.expectedReturn}
                          inflationRate={activeScenario?.inputData?.inflationRate}
                        />
                      )}
                      {advancedTabValue === 1 && (
                        <SensitivityAnalysis 
                          initialData={activeScenario?.inputData || {}} 
                          onCalculate={calculateFIRE} 
                        />
                      )}
                      {advancedTabValue === 2 && (
                        <>
                          <Button onClick={handleRunSimulation} variant="contained" color="primary" sx={{ mb: 2 }}>
                            Run Monte Carlo Simulation
                          </Button>
                          {simulationResults && (
                            <MonteCarloVisualization simulationResults={simulationResults} />
                          )}
                        </>
                      )}
                    </Box>
                  </>
                )}
                {mainTabValue === 3 && (
                  <EducationalContent />
                )}
              </Box>
            </Paper>
            <Button onClick={handleClearAllData} variant="outlined" color="secondary" sx={{ mt: 2 }}>
              Clear All Data
            </Button>
          </Box>
        </Container>
      </Box>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Dialog open={isSaveDialogOpen} onClose={handleSaveDialogClose}>
        <DialogTitle>Save Scenario</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Scenario Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newScenarioName}
            onChange={(e) => setNewScenarioName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveDialogClose}>Cancel</Button>
          <Button onClick={handleSaveScenario}>Save</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default App;