import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { Typography, Box, Paper, useTheme } from '@mui/material';
import { formatCurrency } from '../../utils/formatters';

const COLORS = {
  assets: ['#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A'],
  liabilities: ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#607D8B']
};

const RADIAN = Math.PI / 180;

const AssetLiabilityPieChart = ({ assets, liabilities }) => {
  const theme = useTheme();

  if (!assets || !liabilities) {
    return (
      <Paper elevation={3} sx={{ p: 3, mt: 4, bgcolor: 'background.paper' }}>
        <Typography variant="h6" gutterBottom>Assets and Liabilities Breakdown</Typography>
        <Box sx={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography>No asset or liability data available</Typography>
        </Box>
      </Paper>
    );
  }

  const assetData = Object.entries(assets).map(([name, value], index) => ({ 
    name, 
    value: Number(value), 
    type: 'Asset',
    color: COLORS.assets[index % COLORS.assets.length]
  }));
  const liabilityData = Object.entries(liabilities).map(([name, value], index) => ({ 
    name, 
    value: Number(value), 
    type: 'Liability',
    color: COLORS.liabilities[index % COLORS.liabilities.length]
  }));
  const data = [...assetData, ...liabilityData].filter(item => item.value > 0);

  const totalAssets = assetData.reduce((sum, item) => sum + item.value, 0);
  const totalLiabilities = liabilityData.reduce((sum, item) => sum + item.value, 0);
  const netWorth = totalAssets - totalLiabilities;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentValue = percent * 100;
    
    if (percentValue < 3) return null; // Don't render labels for very small slices
  
    return (
      <text 
        x={x} 
        y={y} 
        fill="white" 
        textAnchor={x > cx ? 'start' : 'end'} 
        dominantBaseline="central"
        fontSize={12}
        fontWeight="bold"
      >
        {`${name} (${percentValue.toFixed(0)}%)`}
      </text>
    );
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 4, bgcolor: 'background.paper' }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
        Assets and Liabilities Breakdown
      </Typography>
      <Box sx={{ height: 400, width: '100%' }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {data.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={entry.color}
                  stroke={theme.palette.background.paper}
                  strokeWidth={2}
                />
              ))}
            </Pie>
            <Tooltip
              formatter={(value, name, props) => [
                formatCurrency(value), 
                `${props.payload.name} (${props.payload.type})`
              ]}
              contentStyle={{ 
                backgroundColor: theme.palette.background.paper, 
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary
              }}
            />
            <Legend 
              layout="vertical" 
              align="right" 
              verticalAlign="middle"
              formatter={(value, entry) => (
                <span style={{ color: theme.palette.text.primary, fontSize: '0.875rem' }}>{value}</span>
              )}
            />
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: COLORS.assets[0] }}>
          Total Assets: {formatCurrency(totalAssets)}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: COLORS.liabilities[0] }}>
          Total Liabilities: {formatCurrency(totalLiabilities)}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
          Net Worth: {formatCurrency(netWorth)}
        </Typography>
      </Box>
    </Paper>
  );
};

export default AssetLiabilityPieChart;