import React from 'react';
import { Paper, Typography, Grid, Box, Divider, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { formatCurrency, formatLargeNumber } from '../utils/formatters';

const ResultsDisplay = ({ results, assets, liabilities }) => {
  if (!results) return null;

  console.log('Assets:', assets);
  console.log('Liabilities:', liabilities);

  const { 
    totalSavings, 
    totalSavingsInTodaysDollars,
    inflationAdjustedExpenses, 
    safeWithdrawalAmount, 
    safeWithdrawalAmountInTodaysDollars,
    yearsOfRetirement,
    totalAssets,
    totalLiabilities,
    netWorth,
    fireNumber
  } = results;

  const ResultItem = ({ title, value, subValue, emoji, tooltip }) => (
    <Box sx={{ mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 1 }}>
      <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '8px', fontSize: '1.5em' }}>{emoji}</span>
        {title}
        {tooltip && (
          <Tooltip title={tooltip} arrow>
            <InfoIcon sx={{ ml: 1, fontSize: '1rem', cursor: 'pointer' }} />
          </Tooltip>
        )}
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'secondary.main' }}>
        {value}
      </Typography>
      {subValue && (
        <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
          {subValue}
        </Typography>
      )}
    </Box>
  );

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', mb: 3 }}>
        🔥 FIRE Projection Results
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ResultItem 
            title="Total Savings at Retirement"
            value={formatLargeNumber(totalSavings)}
            subValue={totalSavingsInTodaysDollars ? `${formatLargeNumber(totalSavingsInTodaysDollars)} in today's dollars` : undefined}
            emoji="💰"
            tooltip={`
              The larger number (${formatLargeNumber(totalSavings)}) is your projected total savings at retirement in future dollars, 
              without adjusting for inflation. The smaller number (${formatLargeNumber(totalSavingsInTodaysDollars)} in today's dollars) 
              is adjusted for inflation to show its equivalent value in today's purchasing power.

              For example, if a house costs $500,000 today, your inflation-adjusted savings could theoretically 
              buy about ${Math.floor(totalSavingsInTodaysDollars / 500000)} such houses. This gives you a tangible way 
              to understand your future purchasing power.
            `}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ResultItem 
            title="Annual Expenses at Retirement"
            value={formatCurrency(inflationAdjustedExpenses)}
            emoji="🛒"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ResultItem 
            title="Safe Withdrawal Amount"
            value={`${formatLargeNumber(safeWithdrawalAmount)}/year`}
            subValue={safeWithdrawalAmountInTodaysDollars ? `${formatLargeNumber(safeWithdrawalAmountInTodaysDollars)} in today's dollars` : undefined}
            emoji="💸"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ResultItem 
            title="Estimated Years of Retirement"
            value={`${yearsOfRetirement} years`}
            emoji="⏳"
          />
        </Grid>
        <Grid item xs={12}>
          <ResultItem 
            title="Your FIRE Number"
            value={formatLargeNumber(fireNumber)}
            subValue="Total savings needed for financial independence"
            emoji="🎯"
            tooltip={`
              Your FIRE Number is the total amount you need to save to achieve financial independence.
              It's calculated based on your expected annual expenses in retirement, adjusted for inflation,
              and your chosen safe withdrawal rate.
            `}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, mb: 3 }}>
        <Typography variant="body1">
          Your FIRE Number is {formatLargeNumber(fireNumber)}. This is the total amount you need to save to achieve financial independence and retire early.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Based on your current plan, you're projected to have {formatLargeNumber(totalSavings)} at retirement, 
          which is {totalSavings >= fireNumber ? 'sufficient' : 'not yet sufficient'} to reach your FIRE Number.
        </Typography>
      </Box>

      {(totalAssets !== undefined || totalLiabilities !== undefined || netWorth !== undefined) && (
        <>
          <Divider sx={{ my: 4 }} />

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', mb: 2 }}>
            Assets and Liabilities
          </Typography>
          
          <Grid container spacing={3} sx={{ mb: 3 }}>
            {totalAssets !== undefined && (
              <Grid item xs={12} md={4}>
                <ResultItem 
                  title="Total Assets"
                  value={formatLargeNumber(totalAssets)}
                  emoji="📈"
                />
              </Grid>
            )}
            {totalLiabilities !== undefined && (
              <Grid item xs={12} md={4}>
                <ResultItem 
                  title="Total Liabilities"
                  value={formatLargeNumber(totalLiabilities)}
                  emoji="📉"
                />
              </Grid>
            )}
            {netWorth !== undefined && (
              <Grid item xs={12} md={4}>
                <ResultItem 
                  title="Net Worth"
                  value={formatLargeNumber(netWorth)}
                  emoji="🏦"
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default ResultsDisplay;