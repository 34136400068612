// utils/historicalData.js
  
  export const historicalData = [
    { year: 1871, stockReturn: 0.1484, bondReturn: 0.0322, inflation: -0.0456 },
    { year: 1872, stockReturn: 0.1089, bondReturn: 0.0413, inflation: -0.0204 },
    { year: 1873, stockReturn: -0.0296, bondReturn: 0.0566, inflation: -0.0156 },
    { year: 1874, stockReturn: 0.1981, bondReturn: 0.0519, inflation: -0.0635 },
    { year: 1875, stockReturn: -0.1076, bondReturn: 0.0371, inflation: -0.0385 },
    { year: 1876, stockReturn: -0.0914, bondReturn: 0.0350, inflation: -0.0480 },
    { year: 1877, stockReturn: -0.0330, bondReturn: 0.0366, inflation: -0.0168 },
    { year: 1878, stockReturn: 0.1366, bondReturn: 0.0345, inflation: -0.0479 },
    { year: 1879, stockReturn: 0.4708, bondReturn: 0.0312, inflation: -0.0032 },
    { year: 1880, stockReturn: 0.2595, bondReturn: 0.0444, inflation: 0.0342 },
    { year: 1881, stockReturn: 0.0005, bondReturn: 0.0371, inflation: 0.0000 },
    { year: 1882, stockReturn: 0.0325, bondReturn: 0.0355, inflation: 0.0165 },
    { year: 1883, stockReturn: -0.0185, bondReturn: 0.0335, inflation: -0.0325 },
    { year: 1884, stockReturn: -0.1857, bondReturn: 0.0348, inflation: -0.0336 },
    { year: 1885, stockReturn: 0.2639, bondReturn: 0.0350, inflation: -0.0127 },
    { year: 1886, stockReturn: 0.1023, bondReturn: 0.0334, inflation: -0.0032 },
    { year: 1887, stockReturn: -0.0351, bondReturn: 0.0323, inflation: 0.0097 },
    { year: 1888, stockReturn: 0.0090, bondReturn: 0.0325, inflation: -0.0032 },
    { year: 1889, stockReturn: 0.0570, bondReturn: 0.0318, inflation: -0.0129 },
    { year: 1890, stockReturn: -0.0732, bondReturn: 0.0300, inflation: -0.0243 },
    { year: 1891, stockReturn: 0.1516, bondReturn: 0.0330, inflation: -0.0034 },
    { year: 1892, stockReturn: 0.0320, bondReturn: 0.0295, inflation: 0.0069 },
    { year: 1893, stockReturn: -0.1855, bondReturn: 0.0348, inflation: -0.0547 },
    { year: 1894, stockReturn: 0.0294, bondReturn: 0.0370, inflation: -0.0462 },
    { year: 1895, stockReturn: 0.0413, bondReturn: 0.0343, inflation: -0.0127 },
    { year: 1896, stockReturn: 0.0094, bondReturn: 0.0343, inflation: -0.0064 },
    { year: 1897, stockReturn: 0.1773, bondReturn: 0.0328, inflation: 0.0064 },
    { year: 1898, stockReturn: 0.2704, bondReturn: 0.0312, inflation: 0.0032 },
    { year: 1899, stockReturn: 0.0254, bondReturn: 0.0298, inflation: 0.0096 },
    { year: 1900, stockReturn: 0.2521, bondReturn: 0.0352, inflation: 0.0120 },
    { year: 1901, stockReturn: 0.1885, bondReturn: 0.0316, inflation: 0.0119 },
    { year: 1902, stockReturn: 0.0839, bondReturn: 0.0306, inflation: 0.0088 },
    { year: 1903, stockReturn: -0.2350, bondReturn: 0.0337, inflation: 0.0291 },
    { year: 1904, stockReturn: 0.2931, bondReturn: 0.0340, inflation: 0.0000 },
    { year: 1905, stockReturn: 0.2903, bondReturn: 0.0298, inflation: -0.0102 },
    { year: 1906, stockReturn: 0.0302, bondReturn: 0.0317, inflation: 0.0206 },
    { year: 1907, stockReturn: -0.3702, bondReturn: 0.0363, inflation: 0.0403 },
    { year: 1908, stockReturn: 0.4625, bondReturn: 0.0375, inflation: -0.0226 },
    { year: 1909, stockReturn: 0.1522, bondReturn: 0.0325, inflation: -0.0066 },
    { year: 1910, stockReturn: -0.0376, bondReturn: 0.0414, inflation: 0.0436 },
    { year: 1911, stockReturn: 0.0330, bondReturn: 0.0409, inflation: 0.0000 },
    { year: 1912, stockReturn: 0.0712, bondReturn: 0.0402, inflation: 0.0208 },
    { year: 1913, stockReturn: -0.1011, bondReturn: 0.0445, inflation: 0.0238 },
    { year: 1914, stockReturn: -0.1184, bondReturn: 0.0445, inflation: 0.0097 },
    { year: 1915, stockReturn: 0.0030, bondReturn: 0.0445, inflation: 0.0220 },
    { year: 1916, stockReturn: 0.0855, bondReturn: 0.0422, inflation: 0.1271 },
    { year: 1917, stockReturn: -0.2153, bondReturn: 0.0444, inflation: 0.1973 },
    { year: 1918, stockReturn: 0.1070, bondReturn: 0.0473, inflation: 0.1769 },
    { year: 1919, stockReturn: 0.1897, bondReturn: 0.0477, inflation: 0.1458 },
    { year: 1920, stockReturn: -0.1685, bondReturn: 0.0799, inflation: 0.1530 },
    { year: 1921, stockReturn: 0.0806, bondReturn: 0.0761, inflation: -0.1060 },
    { year: 1922, stockReturn: 0.2193, bondReturn: 0.0509, inflation: -0.0625 },
    { year: 1923, stockReturn: 0.0136, bondReturn: 0.0489, inflation: 0.0178 },
    { year: 1924, stockReturn: 0.2565, bondReturn: 0.0453, inflation: 0.0000 },
    { year: 1925, stockReturn: 0.2555, bondReturn: 0.0417, inflation: 0.0229 },
    { year: 1926, stockReturn: 0.1062, bondReturn: 0.0363, inflation: 0.0112 },
    { year: 1927, stockReturn: 0.3749, bondReturn: 0.0330, inflation: -0.1667 },
    { year: 1928, stockReturn: 0.4356, bondReturn: 0.0357, inflation: -0.1200 },
    { year: 1929, stockReturn: -0.0854, bondReturn: 0.0392, inflation: 0.0000 },
    { year: 1930, stockReturn: -0.2512, bondReturn: 0.0455, inflation: -0.0642 },
    { year: 1931, stockReturn: -0.4384, bondReturn: 0.0234, inflation: -0.0932 },
    { year: 1932, stockReturn: -0.0864, bondReturn: 0.0893, inflation: -0.1027 },
    { year: 1933, stockReturn: 0.5016, bondReturn: 0.0364, inflation: 0.0076 },
    { year: 1934, stockReturn: -0.0135, bondReturn: 0.0396, inflation: 0.0151 },
    { year: 1935, stockReturn: 0.4098, bondReturn: 0.0458, inflation: 0.0299 },
    { year: 1936, stockReturn: 0.3336, bondReturn: 0.0290, inflation: 0.0145 },
    { year: 1937, stockReturn: -0.3530, bondReturn: 0.0271, inflation: 0.0286 },
    { year: 1938, stockReturn: 0.2928, bondReturn: 0.0358, inflation: -0.0278 },
    { year: 1939, stockReturn: -0.0110, bondReturn: 0.0244, inflation: -0.0143 },
    { year: 1940, stockReturn: -0.1067, bondReturn: 0.0440, inflation: 0.0096 },
    { year: 1941, stockReturn: -0.1277, bondReturn: 0.0202, inflation: 0.0952 },
    { year: 1942, stockReturn: 0.1917, bondReturn: 0.0248, inflation: 0.0909 },
    { year: 1943, stockReturn: 0.2519, bondReturn: 0.0249, inflation: 0.0297 },
    { year: 1944, stockReturn: 0.1903, bondReturn: 0.0260, inflation: 0.0230 },
    { year: 1945, stockReturn: 0.3581, bondReturn: 0.0376, inflation: 0.0219 },
    { year: 1946, stockReturn: -0.0824, bondReturn: 0.0310, inflation: 0.0856 },
    { year: 1947, stockReturn: 0.0550, bondReturn: 0.0257, inflation: 0.1446 },
    { year: 1948, stockReturn: 0.0572, bondReturn: 0.0258, inflation: 0.0780 },
    { year: 1949, stockReturn: 0.1841, bondReturn: 0.0231, inflation: -0.0103 },
    { year: 1950, stockReturn: 0.3081, bondReturn: 0.0228, inflation: 0.0593 },
    { year: 1951, stockReturn: 0.2368, bondReturn: 0.0266, inflation: 0.0600 },
    { year: 1952, stockReturn: 0.1815, bondReturn: 0.0268, inflation: 0.0075 },
    { year: 1953, stockReturn: -0.0105, bondReturn: 0.0290, inflation: 0.0074 },
    { year: 1954, stockReturn: 0.5256, bondReturn: 0.0248, inflation: 0.0000 },
    { year: 1955, stockReturn: 0.3154, bondReturn: 0.0280, inflation: -0.0037 },
    { year: 1956, stockReturn: 0.0656, bondReturn: 0.0308, inflation: 0.0299 },
    { year: 1957, stockReturn: -0.1078, bondReturn: 0.0369, inflation: 0.0290 },
    { year: 1958, stockReturn: 0.4371, bondReturn: 0.0316, inflation: 0.0176 },
    { year: 1959, stockReturn: 0.1206, bondReturn: 0.0422, inflation: 0.0138 },
    { year: 1960, stockReturn: 0.0058, bondReturn: 0.0384, inflation: 0.0167 },
    { year: 1961, stockReturn: 0.2689, bondReturn: 0.0385, inflation: 0.0067 },
    { year: 1962, stockReturn: -0.0881, bondReturn: 0.0398, inflation: 0.0133 },
    { year: 1963, stockReturn: 0.2261, bondReturn: 0.0400, inflation: 0.0164 },
    { year: 1964, stockReturn: 0.1646, bondReturn: 0.0415, inflation: 0.0097 },
    { year: 1965, stockReturn: 0.1252, bondReturn: 0.0444, inflation: 0.0163 },
    { year: 1966, stockReturn: -0.1006, bondReturn: 0.0495, inflation: 0.0289 },
    { year: 1967, stockReturn: 0.2398, bondReturn: 0.0509, inflation: 0.0309 },
    { year: 1968, stockReturn: 0.1106, bondReturn: 0.0569, inflation: 0.0425 },
    { year: 1969, stockReturn: -0.0850, bondReturn: 0.0671, inflation: 0.0543 },
    { year: 1970, stockReturn: 0.0344, bondReturn: 0.0662, inflation: 0.0570 },
    { year: 1971, stockReturn: 0.1431, bondReturn: 0.0573, inflation: 0.0433 },
    { year: 1972, stockReturn: 0.1898, bondReturn: 0.0592, inflation: 0.0332 },
    { year: 1973, stockReturn: -0.1431, bondReturn: 0.0692, inflation: 0.0872 },
    { year: 1974, stockReturn: -0.2607, bondReturn: 0.0777, inflation: 0.1234 },
    { year: 1975, stockReturn: 0.3731, bondReturn: 0.0782, inflation: 0.0698 },
    { year: 1976, stockReturn: 0.2384, bondReturn: 0.0777, inflation: 0.0487 },
    { year: 1977, stockReturn: -0.0718, bondReturn: 0.0754, inflation: 0.0670 },
    { year: 1978, stockReturn: 0.0656, bondReturn: 0.0859, inflation: 0.0902 },
    { year: 1979, stockReturn: 0.1844, bondReturn: 0.0997, inflation: 0.1329 },
    { year: 1980, stockReturn: 0.3240, bondReturn: 0.1264, inflation: 0.1352 },
    { year: 1981, stockReturn: -0.0491, bondReturn: 0.1390, inflation: 0.1019 },
    { year: 1982, stockReturn: 0.2141, bondReturn: 0.1340, inflation: 0.0616 },
  { year: 1983, stockReturn: 0.2251, bondReturn: 0.1135, inflation: 0.0322 },
  { year: 1984, stockReturn: 0.0624, bondReturn: 0.1315, inflation: 0.0422 },
  { year: 1985, stockReturn: 0.3184, bondReturn: 0.1054, inflation: 0.0355 },
  { year: 1986, stockReturn: 0.1849, bondReturn: 0.0816, inflation: 0.0192 },
  { year: 1987, stockReturn: 0.0523, bondReturn: 0.0868, inflation: 0.0366 },
  { year: 1988, stockReturn: 0.1681, bondReturn: 0.0895, inflation: 0.0407 },
  { year: 1989, stockReturn: 0.3149, bondReturn: 0.0850, inflation: 0.0482 },
  { year: 1990, stockReturn: -0.0313, bondReturn: 0.0855, inflation: 0.0541 },
  { year: 1991, stockReturn: 0.3055, bondReturn: 0.0797, inflation: 0.0421 },
  { year: 1992, stockReturn: 0.0762, bondReturn: 0.0703, inflation: 0.0303 },
  { year: 1993, stockReturn: 0.1008, bondReturn: 0.0587, inflation: 0.0296 },
  { year: 1994, stockReturn: 0.0132, bondReturn: 0.0724, inflation: 0.0261 },
  { year: 1995, stockReturn: 0.3758, bondReturn: 0.0584, inflation: 0.0281 },
  { year: 1996, stockReturn: 0.2296, bondReturn: 0.0644, inflation: 0.0293 },
  { year: 1997, stockReturn: 0.3336, bondReturn: 0.0635, inflation: 0.0234 },
  { year: 1998, stockReturn: 0.2858, bondReturn: 0.0526, inflation: 0.0155 },
  { year: 1999, stockReturn: 0.2104, bondReturn: 0.0655, inflation: 0.0219 },
  { year: 2000, stockReturn: -0.0903, bondReturn: 0.0666, inflation: 0.0338 },
  { year: 2001, stockReturn: -0.1189, bondReturn: 0.0516, inflation: 0.0285 },
  { year: 2002, stockReturn: -0.2210, bondReturn: 0.0461, inflation: 0.0159 },
  { year: 2003, stockReturn: 0.2869, bondReturn: 0.0400, inflation: 0.0227 },
  { year: 2004, stockReturn: 0.1088, bondReturn: 0.0427, inflation: 0.0268 },
  { year: 2005, stockReturn: 0.0491, bondReturn: 0.0429, inflation: 0.0340 },
  { year: 2006, stockReturn: 0.1579, bondReturn: 0.0479, inflation: 0.0254 },
  { year: 2007, stockReturn: 0.0549, bondReturn: 0.0463, inflation: 0.0408 },
  { year: 2008, stockReturn: -0.3658, bondReturn: 0.0203, inflation: 0.0091 },
  { year: 2009, stockReturn: 0.2646, bondReturn: 0.0284, inflation: 0.0261 },
  { year: 2010, stockReturn: 0.1508, bondReturn: 0.0316, inflation: 0.0164 },
  { year: 2011, stockReturn: 0.0211, bondReturn: 0.0320, inflation: 0.0316 },
  { year: 2012, stockReturn: 0.1600, bondReturn: 0.0197, inflation: 0.0207 },
  { year: 2013, stockReturn: 0.3239, bondReturn: 0.0224, inflation: 0.0152 },
  { year: 2014, stockReturn: 0.1369, bondReturn: 0.0239, inflation: 0.0076 },
  { year: 2015, stockReturn: 0.0138, bondReturn: 0.0214, inflation: 0.0119 },
  { year: 2016, stockReturn: 0.1196, bondReturn: 0.0184, inflation: 0.0213 },
  { year: 2017, stockReturn: 0.2183, bondReturn: 0.0234, inflation: 0.0211 },
  { year: 2018, stockReturn: -0.0431, bondReturn: 0.0265, inflation: 0.0191 },
  { year: 2019, stockReturn: 0.3149, bondReturn: 0.0222, inflation: 0.0181 },
  { year: 2020, stockReturn: 0.1840, bondReturn: 0.0072, inflation: 0.0124 },
  { year: 2021, stockReturn: 0.2861, bondReturn: -0.0153, inflation: 0.0470 },
  { year: 2022, stockReturn: -0.1871, bondReturn: -0.1308, inflation: 0.0647 },
];

// Utility function to validate a single year's data
const validateYearData = (yearData) => {
  const isValid = (
    typeof yearData.year === 'number' &&
    yearData.year >= 1800 && yearData.year <= new Date().getFullYear() &&
    typeof yearData.stockReturn === 'number' &&
    yearData.stockReturn >= -1 && yearData.stockReturn <= 1 &&
    typeof yearData.bondReturn === 'number' &&
    yearData.bondReturn >= -1 && yearData.bondReturn <= 1 &&
    typeof yearData.inflation === 'number' &&
    yearData.inflation >= -0.5 && yearData.inflation <= 0.5
  );

  if (!isValid) {
    console.warn(`Invalid data for year ${yearData.year}:`, yearData);
  }

  return isValid;
};

// Validate all historical data
const validateHistoricalData = () => {
  const isValid = historicalData.every(validateYearData);
  if (!isValid) {
    console.error("Historical data contains invalid entries.");
  }
  return isValid;
};

// Call this function when the module is loaded
validateHistoricalData();

// Function to adjust returns for inflation
const adjustForInflation = (nominalReturn, inflation) => {
  return (1 + nominalReturn) / (1 + inflation) - 1;
};

export const getHistoricalReturns = (startYear, endYear, options = {}) => {
  const { adjustForInflation: shouldAdjustForInflation = false } = options;

  if (startYear > endYear) {
    throw new Error('Start year must be less than or equal to end year');
  }
  
  console.log(`Fetching data from ${startYear} to ${endYear}`);
  console.log(`Total years of data: ${historicalData.length}`);
  
  const filteredData = historicalData.filter(data => data.year >= startYear && data.year <= endYear);
  
  console.log(`Filtered data length: ${filteredData.length}`);
  
  if (filteredData.length === 0) {
    throw new Error('No data available for the specified year range');
  }

  return filteredData.map(data => {
    if (shouldAdjustForInflation) {
      return {
        ...data,
        stockReturn: adjustForInflation(data.stockReturn, data.inflation),
        bondReturn: adjustForInflation(data.bondReturn, data.inflation)
      };
    }
    return data;
  });
};

// Function to get average returns and inflation for a given period
export const getAverageReturns = (startYear, endYear, options = {}) => {
  const data = getHistoricalReturns(startYear, endYear, options);
  const sum = data.reduce((acc, year) => ({
    stockReturn: acc.stockReturn + year.stockReturn,
    bondReturn: acc.bondReturn + year.bondReturn,
    inflation: acc.inflation + year.inflation
  }), { stockReturn: 0, bondReturn: 0, inflation: 0 });

  const count = data.length;
  return {
    averageStockReturn: sum.stockReturn / count,
    averageBondReturn: sum.bondReturn / count,
    averageInflation: sum.inflation / count
  };
};