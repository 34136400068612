import React from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Paper } from '@mui/material';
import { formatCurrency } from '../../utils/formatters';

const NetWorthProjectionChart = ({ data }) => {
  const theme = useTheme();

  const formatYAxis = (value) => {
    return value >= 1000000 ? `$${(value / 1000000).toFixed(1)}M` : formatCurrency(value);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{ bgcolor: 'background.paper', p: 2, border: `1px solid ${theme.palette.divider}` }}>
          <Typography variant="subtitle2">Year: {label}</Typography>
          <Typography variant="body2" color="success.main">
            Net Worth: {formatCurrency(payload[0].value)}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const retirementYear = data.find(item => item.isRetirementYear)?.year;

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        Net Worth Projection
      </Typography>
      <Box sx={{ height: 400, width: '100%' }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 10,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="netWorthGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={theme.palette.success.main} stopOpacity={0.8}/>
                <stop offset="95%" stopColor={theme.palette.success.main} stopOpacity={0.1}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
            <XAxis 
              dataKey="year" 
              stroke={theme.palette.text.secondary}
              tick={{ fill: theme.palette.text.secondary }}
            />
            <YAxis 
              tickFormatter={formatYAxis} 
              stroke={theme.palette.text.secondary}
              tick={{ fill: theme.palette.text.secondary }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Area 
              type="monotone" 
              dataKey="netWorth" 
              name="Net Worth" 
              stroke={theme.palette.success.main} 
              fill="url(#netWorthGradient)"
            />
            {retirementYear && (
              <ReferenceLine 
                x={retirementYear} 
                stroke={theme.palette.warning.main} 
                label={{ 
                  value: "Retirement", 
                  position: 'top', 
                  fill: theme.palette.warning.main 
                }} 
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </Box>
      <Typography variant="body2" sx={{ mt: 2, color: theme.palette.text.secondary }}>
        This chart shows the projected growth of your net worth over time. The green area represents your net worth, 
        and if applicable, the yellow line indicates your retirement year.
      </Typography>
    </Paper>
  );
};

export default NetWorthProjectionChart;