import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Slider, Paper, Grid, Box, Alert } from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { formatCurrency, formatPercentage } from '../utils/formatters';

const SensitivityAnalysis = ({ initialData, onCalculate }) => {
  const theme = useTheme();
  const [baselineResults, setBaselineResults] = useState(null);
  const [sensitivityParams, setSensitivityParams] = useState({
    monthlyContribution: 0,
    expectedReturn: 0,
    inflationRate: 0,
  });
  const [chartData, setChartData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Initial data received:", initialData);
    if (initialData) {
      const params = {
        monthlyContribution: initialData.monthlyContribution || 0,
        expectedReturn: initialData.expectedReturn || 0,
        inflationRate: initialData.inflationRate || 0,
      };
      console.log("Setting sensitivity params:", params);
      setSensitivityParams(params);

      try {
        const results = onCalculate(initialData);
        console.log("Baseline results calculated:", results);
        setBaselineResults(results);
      } catch (err) {
        console.error("Error calculating baseline results:", err);
        setError("Failed to calculate baseline results. Please check your inputs: " + err.message);
      }
    } else {
      setError("No initial data provided for sensitivity analysis.");
    }
  }, [initialData, onCalculate]);

  const updateChartData = useCallback((results) => {
    if (!results || typeof results.finalPortfolioValue === 'undefined') {
      console.warn("Invalid results for chart data update");
      return;
    }

    const data = Array.from({ length: 30 }, (_, i) => {
      const year = results.currentYear + i;
      const value = results.finalPortfolioValue * Math.pow(1 + sensitivityParams.expectedReturn/100 - sensitivityParams.inflationRate/100, i);
      return { year, value };
    });

    setChartData(data);
  }, [sensitivityParams.expectedReturn, sensitivityParams.inflationRate]);

  useEffect(() => {
    if (baselineResults) {
      updateChartData(baselineResults);
    }
  }, [baselineResults, updateChartData]);

  const handleChange = (param) => (event, newValue) => {
    setSensitivityParams(prev => ({ ...prev, [param]: newValue }));
    try {
      const updatedResults = onCalculate({
        ...initialData,
        [param]: newValue,
      });
      setBaselineResults(updatedResults);
      updateChartData(updatedResults);
    } catch (err) {
      console.error(`Error updating results for ${param}:`, err);
      setError(`Failed to update results for ${param}. Please check your inputs: ` + err.message);
    }
  };

  const formatYAxis = (value) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `$${(value / 1000).toFixed(0)}K`;
    }
    return `$${value}`;
  };

  const renderSlider = (param, min, max, step, label) => (
    <Box sx={{ mb: 2 }}>
      <Typography gutterBottom>
        {label}: {label.includes('Rate') ? formatPercentage(sensitivityParams[param]) : formatCurrency(sensitivityParams[param])}
      </Typography>
      <Slider
        value={sensitivityParams[param]}
        onChange={handleChange(param)}
        valueLabelDisplay="auto"
        min={min}
        max={max}
        step={step}
        valueLabelFormat={label.includes('Rate') ? formatPercentage : formatCurrency}
      />
    </Box>
  );

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!baselineResults) {
    return <Typography>Loading sensitivity analysis...</Typography>;
  }

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        Sensitivity Analysis
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {renderSlider('monthlyContribution', 0, 10000, 100, 'Monthly Contribution')}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderSlider('expectedReturn', 0, 20, 0.1, 'Expected Return Rate')}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderSlider('inflationRate', 0, 10, 0.1, 'Inflation Rate')}
        </Grid>
      </Grid>
      <Box sx={{ height: 400, mt: 4 }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              bottom: 0,
            }}
          >
            <XAxis 
              dataKey="year"
              stroke={theme.palette.text.primary}
              tick={{ fill: theme.palette.text.primary }}
            />
            <YAxis 
              tickFormatter={formatYAxis}
              stroke={theme.palette.text.primary}
              tick={{ fill: theme.palette.text.primary }}
              width={80}
            />
            <Tooltip
              formatter={(value) => formatCurrency(value)}
              contentStyle={{ 
                backgroundColor: theme.palette.background.paper, 
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary
              }}
            />
            <Legend 
              wrapperStyle={{
                color: theme.palette.text.primary
              }}
            />
            <Area type="monotone" dataKey="value" name="Portfolio Value" stroke={theme.palette.primary.main} fill={theme.palette.primary.main} fillOpacity={0.3} />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default SensitivityAnalysis;