// File: src/utils/monteCarloSimulation.js

import { historicalData } from './historicalData';

// Create a Web Worker for running simulations in the background
const simulationWorker = new Worker(new URL('./simulationWorker.js', import.meta.url));

export const runMonteCarloSimulation = async (initialData, simulations = 1000, onProgress) => {
  const { stockAllocation = 0.6 } = initialData;
  const currentYear = new Date().getFullYear();
  
  // Calculate total years needed for each simulation
  const totalYearsNeeded = (initialData.lifeExpectancy || 95) - initialData.currentAge;

  // Ensure we have enough historical data
  if (historicalData.length < totalYearsNeeded) {
    console.error("Not enough historical data for the simulation length.");
    return null;
  }

  // Prepare data for worker
  const workerData = {
    initialData,
    simulations,
    totalYearsNeeded,
    stockAllocation,
    currentYear,
    historicalData
  };

  // Run simulations using Web Worker
  const results = await new Promise((resolve, reject) => {
    simulationWorker.onmessage = (event) => {
      if (event.data.type === 'progress') {
        // Report progress
        if (onProgress) onProgress(event.data.progress);
      } else if (event.data.type === 'result') {
        // Simulation complete
        resolve(event.data.results);
      }
    };
    simulationWorker.onerror = reject;
    simulationWorker.postMessage(workerData);
  });

  const successRate = results.filter(r => r.success).length / results.length;
  const sortedFinalValues = results.map(r => r.finalPortfolioValue).sort((a, b) => a - b);
  const medianPortfolioValue = sortedFinalValues[Math.floor(results.length / 2)];

  return {
    successRate,
    medianPortfolioValue,
    results,
    percentiles: calculatePercentiles(sortedFinalValues),
    worstCase: sortedFinalValues[0],
    bestCase: sortedFinalValues[results.length - 1],
    volatileSpending: calculateVolatileSpending(results),
    largeSpending: calculateLargeSpending(results),
    smallSpending: calculateSmallSpending(results),
    largeEndPortfolioValue: calculateLargeEndPortfolioValue(results),
    smallEndPortfolioValue: calculateSmallEndPortfolioValue(results)
  };
};

// Helper functions (unchanged)
const calculatePercentiles = (sortedValues) => {
  const percentiles = [10, 25, 50, 75, 90];
  return percentiles.reduce((acc, percentile) => {
    acc[percentile] = sortedValues[Math.floor(sortedValues.length * percentile / 100)];
    return acc;
  }, {});
};

const calculateVolatileSpending = (results) => {
  return results.filter(r => {
    const changes = r.withdrawalData.map((d, i, arr) => 
      i > 0 ? Math.abs(d.withdrawal - arr[i-1].withdrawal) / arr[i-1].withdrawal : 0
    );
    return changes.some(change => change > 0.25);
  }).length / results.length;
};

const calculateLargeSpending = (results) => {
  return results.filter(r => {
    const firstYearSpending = r.withdrawalData[0].withdrawal;
    return r.withdrawalData.some(d => d.withdrawal >= firstYearSpending * 1.5);
  }).length / results.length;
};

const calculateSmallSpending = (results) => {
  return results.filter(r => {
    const firstYearSpending = r.withdrawalData[0].withdrawal;
    return r.withdrawalData.some(d => d.withdrawal <= firstYearSpending * 0.5);
  }).length / results.length;
};

const calculateLargeEndPortfolioValue = (results) => {
  return results.filter(r => r.finalPortfolioValue >= r.totalSavings * 2).length / results.length;
};

const calculateSmallEndPortfolioValue = (results) => {
  return results.filter(r => r.finalPortfolioValue > 0 && r.finalPortfolioValue <= r.totalSavings * 0.5).length / results.length;
};